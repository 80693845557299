.testimonial-section {
    text-align: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.testimonials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 150px;
}

.testimonial { /* Apply the same width to both video and email testimonials */
    width: 70%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    text-align: left;
    position: relative;
}

.testimonial-video {
    width: 100%;
    height: 650px; /* Fixed height for uniformity */
    object-fit: cover; /* Ensures video aspect ratio is maintained without stretching */
    border-radius: 5px;
    border: 1px solid #ddd;
}

.testimonial-video:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.testimonial-video::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.6);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    pointer-events: none;
}

.testimonial-video:hover::before {
    background: rgba(0, 0, 0, 0.8);
}

.testimonial-quote {
    font-style: italic;
    margin-top: 15px;
    color: #333;
}

@media (max-width: 768px) {
    .testimonial, .email-testimonial { /* Update for smaller screens */
        width: 90%;
    }

    .testimonial-video {
        height: 300px; /* Adjust height for smaller screens */
    }
}

.email-testimonials-container {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 70%; /* Match the width of video testimonials */
    margin: auto; /* Center the container */
    
}

.email-testimonial {
    margin-bottom: 20px;
    padding: 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* Inherits width from .email-testimonials-container */
}

.testimonial-content {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

.testimonial-author {
    font-weight: bold;
    text-align: right;
    margin-top: 10px;
}
