.contact-section {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.contact-section h2 {
    margin-top: 150px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    
}

.contact-section form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center; /* Add this line */
}

.contact-section input,
.contact-section textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.contact-section textarea {
    height: 150px;
    resize: vertical;
}

.contact-section button {
    padding: 10px 15px;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-section button:hover {
    background-color: #e5e8e5;
}

/* Add additional responsive or specific styling as needed */
