.header {
    position: fixed;
    top: 120px;
    left: 0;
    right: 0;
    z-index: 999; 
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    padding: 10px 20px;
    background-color: #381a58;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Wrapper for logo and navbar */
.header-content {
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    align-items: center;
    color: #fff;
    margin-left: 20px; /* Space between logo and navbar */
}

.navbar a {
    text-decoration: none;
    color: #ffffff;
    margin-left: 20px;
    font-family: 'Arial', sans-serif; /* You can choose a font that closely matches the one in the image */
    font-size: 18px; 
    font-weight: 400; 
}

.navbar a:hover {
    color: #d5d7e7;
}

.logo-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Updated shadow effect */
}

.logo img {
    height: 100px; /* Increase the size of the logo */
    width: auto; /* Maintain the aspect ratio of the logo */
}

.your-text-class {
    font-family: 'Arial', sans-serif; /* You can choose a font that closely matches the one in the image */
    font-size: 24px; /* Adjust the font size as needed */
    font-weight: bold; /* Makes the font bold */
    text-transform: uppercase; /* Transforms the text to uppercase */
    letter-spacing: 2px; /* Adjusts the spacing between letters */
}


/* Media query for iPhone and similarly sized devices */
@media only screen and (max-width: 390px) {
    .header {
        padding: 10px 0; /* Reduce the padding on smaller screens */
        flex-direction: column; /* Stack elements vertically */
    }

    .header-content {
        flex-direction: column; /* Stack logo and navbar vertically */
        align-items: center; /* Center align the stacked items */
    }

    .navbar {
        margin-left: 0; /* Remove the margin left since it's now vertically stacked */
        flex-direction: column; /* Stack navbar items vertically */
        align-items: center; /* Center align the navbar items */
    }

    .navbar a {
        margin-left: 0; /* Remove the margin between links */
        margin-bottom: 10px; /* Add some space below each link */
    }

    .logo-bar {
        padding: 5px 0; /* Reduce padding in the logo bar */
    }

    .logo img {
        height: 80px; /* Adjust the logo size for smaller screens */
    }

    .your-text-class {
        font-size: 20px; /* Adjust the font size for smaller screens */
        letter-spacing: 1px; /* Adjust letter spacing for smaller screens */
    }
}

