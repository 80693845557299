/* Overall layout and design */
.main-section {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 100%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background: #f9f9f9;
}

.upper-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
}

/* Header Image */
.upper-section header h1 {
    margin-top: 200px;
    flex: 1;
    padding: 20px;
}

.upper-section header h1 img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Content Section */
.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

/* Content Section */
.content-section {
    flex: 1;
    max-width: 480px;
    margin: 0 20px;
    margin-top: 300px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    color: #333;
    line-height: 1.6;
    text-align: left;
}

/* Form Section */
.form-section {
    width: 100%;
    padding: 20px;
    text-align: center;
}

.form-section .question p {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
}

.form-section .options button {
    display: block;
    width: 90%;
    margin: 10px auto;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: white; /* White background */
    color: black; /* Black text color */
    border: 1px solid black; /* Black border */
}

.form-section .options button:hover {
    background-color: #f2f2f2;
}

.form-section .options button.selected {
    background-color: #0f0f0f;
    color: white;
}


.form-section .options select {
    width: 45%;
    margin: 10px 5px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-section .options textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-section .next-button,
.back-button {
    display: block;
    width: 100px;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: white; /* White background */
    color: black; /* Black text color */
    border: 1px solid black;
    border-radius: 5px;
}

/* Responsive adjustments for devices less than 768px wide */
@media (max-width: 768px) {
    .upper-section {
        flex-direction: column;
        align-items: center; /* Ensure alignment is centered after changing flex direction */
    }

    .upper-section header h1,
    .content-section {
        width: 100%; /* Full width to prevent overflow */
        padding: 0 15px; /* Add padding to maintain some space from the screen edges */
        box-sizing: border-box; /* Include padding in the width */
    }

    .form-section .options button {
        width: auto; /* Allow the width to be as wide as the content plus padding */
        max-width: calc(100% - 30px); /* Prevent buttons from overflowing */
        margin: 8px auto; /* Center the buttons with automatic horizontal margins */
    }
}

/* Responsive adjustments specifically for iPhone (up to 390px wide) */
@media only screen and (max-width: 390px) {
    body {
        overflow-x: hidden; /* Hide horizontal scroll */
    }

    .upper-section header h1 img {
        max-width: 70%; /* Adjust the image size to fit within the viewport */
        margin: 0 auto; /* Center the image horizontally */
        display: block; /* Remove any extra space below the image */
    }

    .content-section {
        /* No need to set width to 100% as it's already set in the previous media query */
        padding: 15px; /* Adjust padding for comfortable reading */
        margin: 0 auto; /* Center the content section if needed */
    }

    /* Adjust the form section padding and button sizes for smaller screens */
    .form-section {
        padding: 15px;
        margin: 0 auto; /* Center the form section */
    }

    .form-section .options button,
    .form-section .next-button {
        max-width: calc(100% - 30px); /* Full width minus padding */
        width: auto; /* Adjust width based on content size */
        margin: 8px auto; /* Center buttons */
        padding: 12px 15px; /* Adjust padding for button content */
    }
}

/* Back, Next, and Submit Buttons Styling */
.form-section .next-button,
.form-section .back-button,
.form-section .submit-button { /* Add this class for the submit button */
    display: block;
    width: 120px; /* Slightly larger for better visibility */
    margin: 20px auto;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500; /* Slightly bolder */
    cursor: pointer;
    background-color: white; /* White background */
    color: black; /* Black text color */
    border: 1px solid black;
    border-radius: 4px; /* Less rounded for a sleeker look */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.form-section .next-button:hover,
.form-section .back-button:hover,
.form-section .submit-button:hover {
    background-color: #f2f2f2;
    color: black; /* Black text color */
    border: 1px solid black;
    
}

/* Ensure the submit button is styled correctly if it doesn't already have a class */
.form-section [type="submit"] {
    width: 120px;
    margin: 20px auto;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    background-color: white; /* White background */
    color: black; /* Black text color */
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
}

/* Choice Boxes Styling */
.form-section .options button,
.form-section .options select {
    width: 90%; /* Adjust the width as needed */
    max-width: 400px; /* Set a maximum width */
    margin: 10px auto; /* Center the button */
    padding: 10px 20px; /* Padding for better appearance */
    /* Other existing styles... */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .form-section .options button,
    .form-section .options select {
        max-width: calc(100% - 30px); /* Adjust max-width for smaller screens */
        /* Other existing styles... */
    }
}

/* Further adjustments for very small screens like smartphones */
@media only screen and (max-width: 390px) {
    .form-section .options button,
    .form-section .options select {
        max-width: calc(100% - 20px); /* Further reduce max-width for very small screens */
        /* Other existing styles... */
    }
}


.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    padding: 20px;
    border-radius: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin: 20px auto;
    width: 80%;
    max-width: 600px;
    box-sizing: border-box;
    background: #f9f9f9;
}

/* Optional: Responsive adjustments */
@media (max-width: 768px) {
    .form-wrapper {
        width: 90%;
        padding: 15px;
    }
}

@media only screen and (max-width: 390px) {
    .form-wrapper {
        width: 100%;
        padding: 10px;
    }
}
